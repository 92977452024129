export default defineNuxtRouteMiddleware(async (to, _from) => {
	// skip middleware on server
	if (import.meta.server) return;
	// skip middleware on client side entirely
	// if (import.meta.client) return;

	// or only skip middleware on initial client load
	// const nuxtApp = useNuxtApp();
	// if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;

	if (to.params.id) {
		const ticketStore = useTicketStore();
		const { tickets, event } = storeToRefs(ticketStore);

		if (!tickets.value || tickets.value.length === 0 || event.value === null) {
			await ticketStore.getTicketDetailByOrderId(to.params.id);
		}
	} else {
		throw showError({
			statusCode: 404,
			fatal: true,
			message: 'Ticket not found.',
		});
	}
});
